import membersData from "../../data/members.json";

function groupMembersByPosition(position) {
    const members = membersData.filter(member => member.position === position);
    return members.map(member => <Member key={member.nick} information={member} />);
}

function groupFormerMembersByPosition(position) {
    const members = membersData.filter(member => member.position === position);
    return members.map(member => <FormerMember key={member.nick} information={member} />);
}

function FormerMember({ information }) {
    return <div className="column is-full-mobile is-third-tablet is-one-fifth-desktop">
        <div className="has-text-centered">
            <p className="title is-5 mt-4 is-uppercase">{information.name}</p>
        </div>
    </div>
}

function Member({ information }) {
    console.log(information.img);
    const imagePath = information.img ? information.img : 'placeholder.png';
    const topics = information.topics ? information.topics : '-';

    return <div className="column is-full-mobile is-third-tablet is-one-fifth-desktop">
        <div className="has-text-centered">
            <figure className="image is-128x128 center">
                <img
                    className="is-rounded member-image"
                    src={`${process.env.PUBLIC_URL}/images/members/${imagePath}`} alt={information.name} />
            </figure>
            <p className="title is-6 mt-4 is-uppercase is-flex is-justify-content-center is-flex-direction-column" style={{"height": "1.7em"}}>{information.name}</p>
            <p className="is-6 mt-4" style={{"height": "3em"}}>{topics}</p>
        </div>
    </div>
}

export function FormerMembers() {
    const positions = ["Former Members"];
    const groupedMembers = positions.map(groupFormerMembersByPosition);


    return groupedMembers.map((members, idx) => {
        return <>
            <div>
                <div className="is-size-4 mb-4 divider">{positions[idx]}</div>
            </div>
            <div className="columns is-multiline" style={{"align-items": "center", "justify-content": "center"}}>
                {members}
            </div>
        </>;
    });
}

function Members() {
    const positions = ["Group Leader", "Activity Leaders", "PhD Students", "Research Engineers",
    "Master Students", "Undergraduate Students"]; // , "Former Members"];
    const groupedMembers = positions.map(groupMembersByPosition);


    return groupedMembers.map((members, idx) => {
        return <>
            <div>
                <div className="is-size-4 mb-4 divider">{positions[idx]}</div>
            </div>
            <div className="columns is-multiline" style={{"align-items": "center", "justify-content": "center"}}>
                {members}
            </div>
        </>;
    });
}

export default Members;